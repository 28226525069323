<!-- 成绩查询 -->
<template>
  <div class="score-check-war">
    <div class="score-view-war">
      <div class="head">
        <img :src="require('@/assets/logo.png')" height="35px" class="head-img" alt="">艺术考级
      </div>
      <tempalte v-if="!showEmpty">
        <!-- <van-cell-group> -->
        <van-cell 
          v-for="(item, index) in scoreArr"
          :key="item.scoreKey"
          :border="index !== scoreArr.length - 1"
          :title="item.label" 
          :value="scoreData[item.scoreKey]"  />
        <!-- </van-cell-group> -->
        <van-grid :column-num="2">
          <van-grid-item text="考试专业" class="table-header" style="border-left: 1px solid #ebedf0"></van-grid-item>
          <van-grid-item text="考试成绩" class="table-header"></van-grid-item>
          <template v-for="item in applyList" >
            <van-grid-item :key="item.id" :text="item.artMajorName + item.levelName" style="border-left: 1px solid #ebedf0" />
            <van-grid-item :key="item.id">
              <span v-if="item.artScore === 1" class="pass">{{item.scoreName}}</span>
              <span v-else-if="item.artScore === 2" class="pass">{{ item.scoreName }}</span>
              <span v-else-if="item.artScore === 3" class="pass">{{ item.scoreName }}</span>
              <span v-else-if="item.artScore === 4" class="nopass">{{ item.scoreName }}</span>
              <span v-else style="font-size: 16px">暂无成绩</span>
            </van-grid-item>
          </template>
        </van-grid>
      </tempalte>
      <van-empty v-else description="暂无考试成绩！" />
    </div>
    <img :src="require('@/assets/footer.png')" width="100%" class="footer-img" alt=""> 
  </div>
</template>

<script>
import { Cell ,CellGroup, Empty, Grid, GridItem} from 'vant';
import { queryScore } from '@/api'
export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Empty.name]: Empty,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem
  },
  data() {
    return {
      showEmpty: false,
      scoreData: {},
      applyList: [],
      scoreArr: [
        {label: '考生姓名', scoreKey: 'studName'},
        {label: '拼音', scoreKey: 'studPyName'},
        {label: '性别', scoreKey: 'studSexName'},
        {label: '身份证号', scoreKey: 'idCard'},
        {label: '出生日期', scoreKey: 'studBirthday'},
        {label: '国家', scoreKey: 'studCountry'},
        {label: '民族', scoreKey: 'nationName'},
        {label: '联系电话', scoreKey: 'phone'},
      ]
    }
  },
  created() {
    this.idCard = this.$route.query.idCard
    this.getScoreList()
  }, 
  methods: {
    getScoreList() {
      const params = {
          idCard: this.idCard
        }
        this.saveLoading = true
        queryScore(params).then((res) => {
          this.saveLoading = false
          this.applyList = res.resultData || []
          if (res.resultData.length > 0) {
            this.scoreData = res.resultData[0]
            this.scoreData.studSexName = this.scoreData.studSex === 1 ? '男' : '女'  
            const temp = this.scoreData.idCard.substring(4, 15)
            this.scoreData.idCard = this.scoreData.idCard.replace(temp, '***********')
            this.showEmpty = false
          } else {
            this.showEmpty = true
          }
        }).catch(() => {
          this.saveLoading = false
        })
    }
  } 
}
</script>
<style lang="less">
.van-cell, .van-grid-item__text {
  font-size: 16px;
}
</style>
<style lang="less" scoped>
.score-check-war {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.score-view-war {
  position: relative;
  height: 100%;
  padding: 10px;
  flex: 1;

  .head {
    font-size: 25px;
    text-align: center;
    padding: 10px;
    margin: 20px 0 20px;
  }
  .head-img {
    vertical-align: middle;
    margin-right: 15px;
  }
  .pass {
    color: green;
    font-size: 16x;
    font-weight: 500;
  }
  .nopass {
    color: red;
    font-size: 16px;
    font-weight: 500;
  }
  // ::v-deep .van-cell {
  //   padding: 10px 0;
  // }
}
::v-deep .table-header {
  .van-grid-item__content {
    background: #fafafa;
  }
}
.footer-img {
  padding: 20px 80px;
}
</style>